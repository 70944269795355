body {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #555;
  background-color: #fdfdfd;
  background-image: url("./assets/backgrounds/gplaypattern.png");
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  padding: 0px;
  line-height: 20px;
}

a {
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  margin: 10px 0;
  line-height: 20px;
  text-rendering: optimizelegibility;
}

input[type="text"] {
  color: #ccc;
}

p {
  line-height: 1.7;
  font-size: 13px;
  margin: 0 0 10px;
}
